.avatar-background {
  position: relative;
}

.avatar-background::before {
  content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(255,255,255,0.5);
    z-index: 1;
    border-radius: 5px;
}

.avatar {
  min-height: 30rem;
  height: 100%;
  max-height: 30rem;
}

@media only screen and (max-width: 767px) {
  .avatar {
    max-height: 30rem;
  }
}

@media only screen and (min-width: 768px) {
  .avatar {
    max-height: 50rem;
  }
}